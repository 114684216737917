import React, { useEffect, useState } from "react"
import { Box, Grid, Heading, Container } from "@theme-ui/components"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const HighlightText = ({ block }) => {
  const [wordList, setWordList] = useState()
  const { text } = block

  const splitByWordCount = (str) => {
    var arr = str.split("<br>")
    console.log("arr",arr)
    return arr
  }

  useEffect(() => {
    if (text) {
      var el = document.createElement("html")
      el.innerHTML = text
      const paragraph = el.getElementsByTagName("p")[0].innerHTML
      setWordList(splitByWordCount(paragraph))
    }
  }, [])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.utils.toArray(".text-highlight").forEach(highlight => {
      ScrollTrigger.create({
        trigger: highlight,
        start: "-100px center",
        end: "end end",
        onEnter: () => highlight.classList.add("active"),
        onLeaveBack: () => highlight.classList.remove("active"),
      })
    })
  }, [wordList])

  return (
    <Box sx={{ position: "relative", mt: [4, 6, 7, 9],  }}>
      <Container sx={{ py: [0, 0],  }}>
        {wordList && (
          <Box
            as="p"
            sx={{
              fontSize: [4,9],
              lineHeight: "1",
              pt:[4],
              borderTop:"1px solid", borderColor:"primary"
            }}
          >
            {wordList.map((text,index) => (
              <Box as="span" className="text-highlight" sx={{
                color: "primary",
                opacity: 0.1,
                transition: `opacity .6s ease-in-out`,
                fontWeight:"400",
                fontFamily:"heading",
                fontSize:[6,8,9,11],
                lineHeight:1,
                "&.active": {
                  opacity: 1,
                  transition: `opacity .6s ease-in-out ${index/8}s`,
                },
              }}> {text}<br/></Box>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default HighlightText
