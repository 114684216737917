import React, { useRef, useState, useEffect } from "react"
import {
  Box,
  Grid,
  Text,
  Heading,
  Container,
  Image,
} from "@theme-ui/components"
import RichContentStructuredText from "../RichContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "../../utils/link"

const StoryBlock = ({ block }) => {
  const { stories, link } = block

  const getGridStructure = index => {
    switch (index) {
      case 0:
        return ".7fr .3fr"
        break
      case 1:
        return "minmax(auto,680px) auto"
        break
      case 2:
        return "auto minmax(auto,726px) "
        break
      case 3:
        return ".3fr .7fr"
        break
      case 4:
        return ".7fr .3fr"
        break
      case 5:
        return "auto minmax(auto,726px) "
        break
    }
  }

  return (
    <>
      {stories.map((story, index) => (
        <Box sx={{ pb: [4, 4] }}>
          <Grid
            columns={["1fr", "1fr", getGridStructure(index)]}
            sx={{
              maxWidth: "1482px",
              mr: index === 2 || index === 3 || index === 5 ? 0 : "auto",
              ml: index === 2 || index === 3 || index === 5 ? "auto" : 0,
              gridColumnStart: "revert",
            }}
            gap={[0, 0]}
          >
            <Box
              sx={{
                gridColumn: [
                  1,
                  1,
                  index === 2 || index === 3 || index === 5 ? 2 : 1,
                ],
                gridRow: [1, 1, 1],
                p: [0, 3],
                py: [0, 0],
                px: [0, 4],
              }}
            >
              <ResponsiveImage index={index} story={story} />
            </Box>

            <Box
              sx={{
                gridRow: [2, 2, 1],
                display: "flex",
                flexDirection: ["column", "column", "row"],
                justifyContent: [
                  "flex-start",
                  "flex-start",
                  index === 2 || index === 3 || index === 5
                    ? "flex-end"
                    : "flex-start",
                ],
                "& > div": {
                  maxWidth: ["100%", "100%", "348px"],
                  display: "flex",
                  alignItems: index === 0 ? "flex-start" : "flex-end",
                  height: "100%",
                },
                p: {
                  fontSize: [2, 3, 3, 4],
                  mb: [0, 0],
                },
                px: [4, 4],
                pr: index === 2 || index === 3 || index === 5 ? [4, 0] : [4, 4],
                pl: index === 2 || index === 3 || index === 5 ? [4, 4] : [4, 0],
              }}
            >
              <Heading
                as="h2"
                variant="h2"
                sx={{
                  display: ["block", "block", "none"],
                  maxWidth: ["100%", "636px"],
                  color: "dark",
                  my: [3, 3, 0],
                  fontSize: [4, "4.875rem", "5.775rem"], //"4.5rem",
                }}
              >
                {story.title}
              </Heading>
              <RichContentStructuredText text={story.body} />
            </Box>
          </Grid>
        </Box>
      ))}
      <Box sx={{
        width:"100%",
        textAlign:"center",
        my:[4,6]
      }}>
        <OutboundLink
          variant="links.dark"
          sx={{
            color: "text",
            textDecoration: "none",
          }}
          href={link.url}
        >
          {link.anchor}
        </OutboundLink>
      </Box>
    </>
  )
}

const ResponsiveImage = ({ story, index }) => {
  const imageRef = useRef()
  const isBrowser = typeof window !== "undefined"
  const [imageHeight, setImageHeight] = useState()

  useEffect(() => {
    function handleResize() {
      if (imageRef && imageRef.current)
        setImageHeight(imageRef.current.offsetWidth)
    }

    if (isBrowser) window.addEventListener("resize", handleResize)

    setImageHeight(imageRef.current.offsetWidth)
  }, [])

  return (
    <Box
      ref={imageRef}
      sx={{
        position: "relative",
        height: imageHeight,
        width:
          index % 6 === 0 ? ["100%", "100%", "100%", "100%"] : ["100%", "100%"],
        maxWidth: "1104px",
        ".gatsby-image-wrapper, img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        overflow: "hidden",
      }}
    >
      {story.image.gatsbyImageData ? (
        <GatsbyImage image={story.image.gatsbyImageData} />
      ) : (
        <Image
          src={story.image.video.thumbnailUrl}
          alt={`Immmagine foto ${story.title}`}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: [3, 4],
          left: [0, 0],
          zIndex: 2,
          px: [0, 3, 3, 4],
        }}
      >
        <Heading
          as="h2"
          variant="h2"
          sx={{
            display: ["none", "none", "block"],
            maxWidth: "636px",
            color: "light",
            my: [3, 0],
            fontSize: [4, "4.875rem", "4.575rem"], //"4.5rem",
          }}
        >
          {story.title}
        </Heading>
      </Box>
    </Box>
  )
}

export default StoryBlock
