import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../layouts/Layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "../layouts/Hero/Page"
import { useFavicon } from "../hooks/useFavicon"
import TitleAndBody from "../components/TitleAndBody"
import RichContentStructuredText from "../components/RichContentStructuredText"
import CalendarMonths from "../components/CalendarMonths"
import CustomImage from "../components/Image"
import HighlightText from "../components/HighlightText"
import StoryBlock from "../components/StoryBlock"

const Page = ({ data: { page, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.body && (
        <Container
          sx={{
            maxWidth: "758px",
            h1: {
              textAlign: ["center", "unset"],
              textTransform: "lowercase",
              fontSize: [8, 9, 11], //"4.5rem",
              fontFamily: "heading",
              lineHeight: "heading",
              fontWeight: "heading",
              color: "text",
              mb: [2, 2, 5],
              mt: [4, 4, 5],
              em: {
                "-webkit-text-stroke": "1px #181818",
                color: "#181818",
                fontStyle: "normal",
                fontFamily: "body",
                fontWeight: "400",
              },
            },
            h2: {
              color: "text",
              margin: "inherit",
              mt: [4, 8],
              fontSize: [4, 8],
              lineHeight: "1.167",
              em: {
                fontStyle: "normal",
                color: "primary",
              },
            },
          }}
        >
          <RichContentStructuredText text={page.body} />
        </Container>
      )}
      {page.content.map(block =>
        block && block.model && block.model.apiKey ? (
          <Box as="section" key={block.id}>
            {block.model.apiKey === "title_and_body" && (
              <TitleAndBody block={block} theme={"light"} />
            )}
            {block.model.apiKey === "image" && (
              <Container>
                <CustomImage block={block} />
              </Container>
            )}
            {block.model.apiKey === "months_collection" && (
              <CalendarMonths block={block} />
            )}
            {block.model.apiKey === "marquee" && (
              <HighlightText block={block} />
            )}
            {block.model.apiKey === "story_block" && (
              <StoryBlock block={block} />
            )}
          </Box>
        ) : (
          ""
        )
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsImage {
          image {
            url
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsMonthsCollection {
          title
          months {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
            position
            thumbnailImage {
              url
              gatsbyImageData(
                placeholder: NONE
                forceBlurhash: false
                imgixParams: {
                  fit: "crop"
                  ar: "1:1"
                  h: "1000"
                }
              )
            }
            body {
              value
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoryBlock {
          id
          stories {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          link {
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsMarquee {
          id
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleImageVideo {
          media {
            url
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
        }
        ... on DatoCmsVideo {
          video {
            url
          }
        }
        ... on DatoCmsColorPalette {
          colors {
            ... on DatoCmsColor {
              description
              color {
                red
                green
                blue
                alpha
                rgb
                hex
              }
            }
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsNews {
              ...NewsDetails
              ...NewsAllSlugLocales
            }
          }
        }
      }
      value
    }
    link {
      ... on DatoCmsInternalLink {
        id
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsBlogPage {
            ...BlogDetails
          }
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsNews {
            ...NewsDetails
            ...NewsAllSlugLocales
          }
        }
      }
      ... on DatoCmsExternalLink {
        id
        anchor
        url
        model {
          apiKey
        }
      }
    }

    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    body {
      value
    }
    model {
      apiKey
    }
    heroImage {
      url
      gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
      video {
        thumbnailUrl
      }
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
