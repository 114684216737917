import React, { useEffect, useRef, useState } from "react"
import { Box, Container, Heading, Grid, Flex, Image, Text } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import RichContentStructuredText from "../RichContentStructuredText"
import { InboundLink } from "../../utils/link"
import { getPagePath } from "../../utils/path"

const colors = ["#376BB0"]

const CalendarMonths = ({ block }) => {
  const { months } = block
  const [sortedMonths, setSortedMonths] = useState()

  useEffect(()=>{
    setSortedMonths(months.sort((a,b)=> b.position - a.position ))
  },[])

  return (
    <Box sx={{ px: [4, 4], py: [4, 7] }}>
      <Grid columns={["1fr", "1fr 1fr","1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"]} sx={{
        alignItems:"flex-end"
      }}>
        {sortedMonths && sortedMonths.map((month, index) => (
          <>
            <ResponsiveImage month={month} index={index } />
          </>
        ))}
      </Grid>
    </Box>
  )
}

const ResponsiveImage = ({ month, index }) => {
  const imageRef = useRef()
  const isBrowser = typeof window !== "undefined"
  const [imageHeight, setImageHeight] = useState()

  const getGridStructure = index => {
    switch (index) {
      case 0:
        return ["1","1/2","1/2","1/6"]
        break
      case 1:
        return ["1","2/2","2/2","6/9"]
        break
      case 2:
        return ["1","1/2","1/2","1/4"]
        break
      case 3:
        return ["1","2/2","2/2","4/7"]
        break
      case 4:
        return ["1","1/2","1/2","4/9"]
        break
      case 5:
        return ["1","2/2","2/2","1/6"]
        break
      case 6:
        return ["1","1/2","1/2","6/9"]
        break
      case 7:
        return ["1","2/2","2/2","1/4"]
        break
      case 8:
        return ["1","1/2","1/2","4/7"]
        break
      case 9:
        return ["1","2/2","2/2","4/9"]
        break
      case 10:
        return ["1","1/2","1/2","1/6"]
        break
      case 11:
        return ["1","2/2","2/2","6/9"]
        break
    }
  }

  useEffect(() => {
    function handleResize() {
      if (imageRef && imageRef.current)
        setImageHeight(imageRef.current.offsetWidth)
    }

    if (isBrowser) window.addEventListener("resize", handleResize)

    setImageHeight(imageRef.current.offsetWidth)
  }, [])

  return (
    <Box
      ref={imageRef}
      sx={{
        position: "relative",
        height: imageHeight,
        gridColumn: getGridStructure(index),
        // width:
        //   index % 6 === 0
        //     ? ["100%", "100%", "60%", "60%"]
        //     : ["100%", "100%", "40%", "40%"],
        ".gatsby-image-wrapper, img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        overflow: "hidden",
      }}
    >
      <InboundLink
        href={getPagePath(month, month.locale)}
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "inline-block",
          zIndex: 0,
          "&:after": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "overlay",
            width: "100%",
            height: "100%",
          },
          
        }}
      >
        {month.thumbnailImage.gatsbyImageData ? (
          <GatsbyImage image={month.thumbnailImage.gatsbyImageData} />
        ) : (
          <Image
            src={month.thumbnailImage.video.thumbnailUrl}
            alt={`Immmagine foto ${month.title}`}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: [3, 4],
            left: [3, 4],
            maxWidth: ["180px", "404px"],
            zIndex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: [2, 3, 6],
              color: "light",
              lineHeight: "1",
            }}
          >
            calendario
          </Text>
          <Heading
            as="h2"
            variant="h2"
            sx={{
              color: "light",
              my: [3, 0],
              textTransform: "lowercase",
              fontSize: [8, 9,9,10], //"4.5rem",
            }}
            dangerouslySetInnerHTML={{ __html : month.title.split(" ")[0]+"<br/>"+month.title.split(" ")[1]}}
          />
            
        </Box>
      </InboundLink>
    </Box>
  )
}

export default CalendarMonths
